<template>
  <div class="page">
    <Navbar title="用户绑定" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-row class="title">
        <van-col span="24">商户认证</van-col>
      </van-row>
      <van-cell-group>
        <van-field
          v-model="form.name"
          label="名称"
          placeholder="请输入名称"
          :rules="[{ required: true, message: '请填写名称' }]"
        >
        </van-field>
        <van-field label="商户类型"
          ><template #input>
            <van-radio-group
              v-model="form.type"
              :checked-color="COLOR_M"
              direction="horizontal"
              @change="typeChange"
            >
              <van-radio name="PSN">个人</van-radio>
              <van-radio name="ETP">企业</van-radio>
            </van-radio-group></template
          ></van-field
        >
        <van-field
          v-model="form.identify"
          label="身份证号"
          v-if="form.type === 'PSN'"
          placeholder="请输入身份证号"
          :rules="[{ required: true, message: '请填写身份证号' }]"
        >
        </van-field>
        <van-field
          v-model="form.identify"
          label="统一社会信用代码"
          v-if="form.type === 'ETP'"
          placeholder="请输入统一社会信用代码"
          :rules="[{ required: true, message: '请填写统一社会信用代码' }]"
        >
        </van-field>
        <van-field
          v-model="form.userName"
          label="管理员姓名"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        >
        </van-field>
        <van-field
          v-model="form.userIdentity"
          label="管理员身份证"
          placeholder="请输入身份证号"
          :rules="[{ required: true, message: '请填写身份证号' }]"
        >
        </van-field>
        <van-field
          v-model="form.vcode"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template>
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              :color="COLOR"
              size="small"
              native-type="submit"
            >
              保存认证
            </van-button></van-col
          >
        </van-row>
      </van-cell-group>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <Share ref="share" />
  </div>
</template>
<script>
import Supplier from './Supplier.js'
export default {
  ...Supplier
}
</script>
<style lang="less" scoped>
@import './Supplier.less';
</style>
