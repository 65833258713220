import { Form, Field, CellGroup, RadioGroup, Radio } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        [Form.name]: Form,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio
    },
    data () {
        return {
            operatorCode: '',
            sendBtnTime: 0,
            loadingShow: false,
            serialCode: '1',
            validateState: false,
            operate: 'CRE',
            upper: '1',
            user: {},
            supplier: { code: '', name: '', identify: '', type: '' },
            form: {
                name: '',
                type: 'PSN',
                identify: '',
                userName: '',
                userIdentity: '',
                vcode: ''
            }
        }
    },
    mounted () {
        this.retrieveUser()
    },
    methods: {
        async retrieveUser () {
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveLoginUser')
            if (res.status === '200') {
                this.user = res.data
                this.form.userName = res.data.name
                this.form.userIdentity = res.data.identity
                this.retrieveUserSupplier()
            }
        },
        async retrieveUserSupplier () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user }
            const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/retrieveUserSupplier', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.supplier = res.data
                this.form.type = res.data.type
                this.operate = 'UPD'
            }
            this.typeChange()
        },
        typeChange () {
            if (this.form.type === 'ETP') {
                this.form.name = this.supplier.name
                this.form.identify = this.supplier.identify
            } else if (this.form.type === 'PSN') {
                this.form.name = this.user.name
                this.form.identify = this.user.identity
            }
        },
        async submit () {
            if (this.operate === 'CRE') {
                this.createSupplier()
            } else if (this.operate === 'UPD') {
                this.updateSupplier()
            }
        },
        async createSupplier () {
            if (this.validateState) {
                this.loadingShow = true
                var pd = { name: this.form.name, identify: this.form.identify, type: this.form.type, module: 'HRE', userName: this.form.userName, userIdentity: this.form.userIdentity, vcode: this.form.vcode }
                const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/createSupplier', this.$qs.stringify(pd))
                this.loadingShow = false
                if (res.status === '200') {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: '认证保存成功'
                    }).then(() => {
                        this.$router.go(-1)
                    })
                } else {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: res.msg
                    }).then(() => {
                        // on close
                    })
                }
            } else {
                this.$dialog.alert({
                    title: '验证码提示',
                    message: '请点击发送手机验证码'
                }).then(() => {
                    // on close
                })
            }
        },
        async updateSupplier () {
            if (this.validateState) {
                this.loadingShow = true
                var pd = { code: this.supplier.code, name: this.form.name, identify: this.form.identify, type: this.form.type, module: this.supplier.module, userName: this.form.userName, userIdentity: this.form.userIdentity, vcode: this.form.vcode }
                const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/updateSupplier', this.$qs.stringify(pd))
                this.loadingShow = false
                if (res.status === '200') {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: '认证保存成功'
                    }).then(() => {
                        this.$router.go(-1)
                    })
                } else {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: res.msg
                    }).then(() => {
                        // on close
                    })
                }
            } else {
                this.$dialog.alert({
                    title: '验证码提示',
                    message: '请点击发送手机验证码'
                }).then(() => {
                    // on close
                })
            }
        },
        async sendValidate () {
            var pd = { phone: this.user.phone }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/sendValidate', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.validateState = true
                this.sendBtnTime = 60
                this.countdown()
            }
        },
        countdown () {
            var that = this
            setTimeout(function () {
                that.sendBtnTime--
                if (that.sendBtnTime > 0) {
                    that.countdown()
                }
            }, 1000)
        }
    }
}
